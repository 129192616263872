import React from 'react';
import s from './MyTextarea.module.css'
import classnames from "classnames";

type PropsType = {
    placeholder?: string,
    id?: string
    name?: string
    value?: string
    onChange?: any
    isError?: any
    touched?: any
}

const MyTextarea = (props: PropsType) => {
    return (
        <textarea {...props} className={classnames(s.textarea, props.isError && props.touched ? s.error : '')}/>
    );
};

export default MyTextarea;
