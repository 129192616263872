import React, {useState} from 'react';
import s from './MobileHeader.module.css'
import logo from '../../assets/img/logo.svg'
import burger from '../../assets/img/burger.svg'
import { useNavigate} from "react-router-dom";
import NavBar from "../Header/NavBar/NavBar";

const MobileHeader = () => {

    const [navIsShow, setNavIsShow] = useState(false)
    const navigate = useNavigate()

    return (
        <div className={s.wrapper}>
            <div className={s.logo_block}>
                <img src={logo} alt="" onClick={() => navigate("/")} style={{width: '100px'}}/>
            </div>
            <div className={s.right}>
                <div>
                    <img src={burger} alt="" onClick={() => setNavIsShow(true)}/>
                </div>
            </div>

            <NavBar isActive={navIsShow} setIsActive={setNavIsShow}/>

        </div>
    );
};

export default MobileHeader;
