import React from 'react';
import {motion} from "framer-motion";
import s from "./TermsAndConditions.module.css"

const TermsAndConditions = () => {


    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
           <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.main_text_block}>
                        <div className={s.title}>
                                Terms & Conditions
                        </div>
                        <div className={s.main_text}>
                        Fedest Terms of Service and the Portal's Terms of Use                        </div>
                    </div>
                </div>

                    <div className={s.content}>
                        <div className={s.content_title}>
                            General Terms
                        </div>

                        <div className={s.subtitle}>
                            Terminology
                        </div>

                        <ul className={s.text_block}>
                            <h6>The following deﬁnitions will be applicable throughout these Terms and Conditions:</h6>
                            <li>Shipment: a load of goods that are sent from one place to another consisting of one or more items.</li>
                            <li>Item: element of a shipment, namely parcel, or any other object appropriately packed for shipping.</li>
                            <li>Logistics provider: a company selected by Fedest that offers logistics services or assigns them within its network (for example a carrier, a courier company, a freight forwarder and others).</li>
                            <li>Logistics service: a service including but not limited to dispatching, transportation, delivery, warehousing, fulﬁlment and other services offered (but not rendered directly) by Fedest.</li>
                            <li>Business day: every day except for Saturdays, Sundays, national holidays and bank holidays in the countries of pick-up, transit or delivery of the shipment</li>
                            <li>Chargeable weight: the weight expressed in kilograms that is charged by the logistics provider. It may be the absolute (mass) weight of the item or the volumetric (dimensional) weight, whichever is greater.</li>
                            <li>Volumetric weight: is the space the item occupies in relation to its volume calculated by applying the formula of the logistics provider assigned. It is based on the actual dimensions of the item.</li>
                            <li>CMR: Convention on the Contract for the International Carriage of Goods by Road (Geneva, 19 May 1956).</li>
                            <li>Warsaw convention: Convention for the Uniﬁcation of Certain Rules Relating to International Carriage by Air, (Warsaw, 12 October 1929) – applicable only clauses governing cargo shipments (not baggage).</li>
                            <li>Montreal convention: Convention for the Uniﬁcation of Certain Rules for International Carriage by Air (Montreal, 28 May 1999) – applicable only clauses governing cargo shipments (not baggage).</li>
                            <li>The Hague–Visby Rules: International Convention for the Uniﬁcation of Certain Rules of Law relating to Bills of Lading (the Hague, 1924) and its Protocols.</li>
                            <li>A reference to a statute or statutory provision is a reference to amended or re-enacted versions thereof and related subordinate legislation.</li>
                            <li>You/the client/he/she: person or persons (natural of any gender or legal), requesting the booking of logistics services, provided by logistics providers selected by Fedest.</li>
                            <li>A reference to a person is understood to be any natural person, acting within or outside the scope of economic activity (trade, business, craft, liberal profession) or any legal entity.</li>
                            <li>A reference to any party of any contract Fedest may enter into within these Terms & Conditions also includes its successors.</li>
                            <li>Fedest: Fedest Tranport & Logistic SL.</li>
                        </ul>

                        <div className={s.subtitle}>
                            Fedest Transport & Logistic SL
                        </div>

                        <div className={s.text_block}>
                            <p>
                            Fedest Transport & Logistic SL is a private limited liability company, with registration number B66385063, with its registered address at Calle Antoni Sedo 80, 08191 Rubi, Spain, that manages the fedest.eu and offers booking of logistics services directly to the clients.
                            </p>
                            <p>
                            Fedest acts as a freight forwarder and not as a carrier. When placing the order through the established communication channel on fedest.eu, the client authorizes Fedest to, in its own name (Fedest’s name) and on behalf of the client (consignor), order logistics services with one of the logistics providers, subject to the condition that the selected logistics provider complies with the requirements for the execution of the ordered service.
                            </p>
                            <p>
                            Established communication channels: the client may contact Fedest in writing through the contact form available at fedest.eu, or via a direct e-mail address of a Fedest representative using info@fedest.eu. For the purpose of providing Fedest with any information that may cause signiﬁcant changes to the contractual relationship with the client, such as but not limited to cancellation or any other kind of modiﬁcation of a placed order, or the invoicing method, or for the purpose of opening a claim or a complaint procedure, only written communication channels will be accepted. Whichever such information provided through any other channel of communication must be conﬁrmed by the client and by Fedest in writing to the client`s email address provided in the ordering process. Whichever communication channel is used, the client needs to clearly state his order number and any other details necessary to identify himself (such as name and email address).
                            </p>
                        </div>

                        <div className={s.content_title}>
                            2. General rules of booking the service
                        </div>
                        <div className={s.text_block}>
                            <p>The client may use the following way to place an order with Fedest.</p>
                        </div>

                        <div className={s.subtitle}>
                            2.1 Ordering process
                        </div>
                        <div className={s.text_block}>
                            <p>A client place an order via an established communication channel directly with a Fedest representative. The user must provide all the necessary information in order for Fedest to be able to book the logistics service with the logistics provider as per the client`s request. Such order may be processed as per the conﬁrmation of these Terms and Conditions and the Terms and Conditions of any applicable logistics service provider in every individual case. In the offer email, Fedest may will attach the Terms and Conditions document (or a link to the Terms and Conditions). The client has to familiarize himself with the Terms and Conditions. By agreeing to the offer, the client agrees to the provided Terms and Conditions. In case a representative of a corporate client is conﬁrming the offer and thereby these Terms and Conditions he is deemed to be authorized to act within his right and capacity to accept the offer and the Terms and Conditions. The client can agree with the Terms and Conditions for a single order, several orders for a deﬁned period of time or for an undeﬁned period of time (until the Terms and Conditions change). Upon changing the Terms and Conditions Fedest is responsible for providing the client with the updated text of the Terms and Conditions.</p>
                            <p>The order for the services may be processed based on the contractual agreement in place between the client and Fedest. In such a contract the client and Fedest can agree on provisions different from these Terms and Conditions provided that in case of discrepancies between these Terms and Conditions and the contract, the contract shall prevail.</p>
                            <p>As soon as the client sends all the necessary information for Fedest to be able to book the logistics service in case of an existing contractual agreement or if the client agrees to the relevant Terms and Conditions as described above, an order is considered as placed with the client effectively conﬁrming the payment obligation as per one of the payment methods deﬁned in section 2.2.</p>
                            <p>Once the order is considered as placed, Fedest proceeds with the booking of the logistics service with the selected logistics provider. As soon as the logistics service is booked on behalf of the client the order is considered as successfully submitted and an email conﬁrmation is sent to the client.</p>
                        </div>
                        <div className={s.subtitle2}>2.1.2 Acknowledgement presumption</div>
                        <div className={s.text_block}>
                            <p>The client understands that all ordering processes lead to an order placed for a speciﬁc service type further described in Chapter 3. The client is responsible to familiarize himself and comply with the Terms and Conditions of the logistics provider as well as the Terms and Conditions of Fedest in full and particularly with the relevant section describing the service the order was placed for. Even if the client neglected his duty to familiarize himself with these Terms and Conditions his acknowledgement shall be presumed. Fedest is not obliged to provide the client with any additional translation of these Terms and Conditions or the Terms and Conditions of the logistics provider.</p>
                        </div>
                        <div className={s.subtitle}>
                        2.2 Payment process
                        </div>
                        <div className={s.text_block}>
                            <p>When the client conﬁrms an order, the invoices for the services of Fedest are issued and sent to the client’s email that was speciﬁed during the order process. Any changes made to the order may cause a need for issuing of an additional accounting document such as an invoice or a credit note. An accounting document is considered as brought to the notice of the client after it is either sent via email or sent to the client via any other established communication channel. It is the client’s responsibility to control their email and other established communication channels regularly and pay any due amounts based on the accounting documents received.</p>
                        </div>
                        <div className={s.subtitle2}>2.2.1 Payment via bank transfer</div>
                        <div className={s.text_block}>
                            <p>Any bank-related charges, generated by the bank transfer, will be exclusively borne by the client. If the amount, received on Fedest’s bank account, is less than that which should have been deposited, the order will not be conﬁrmed. Fedest is not obliged to notify the user that the deposited amount was not sufﬁcient to conﬁrm the order payment. Wire transfers should only be paid in the currency and to the bank account speciﬁed in the ordering process or the transactional email provided. Any currency conversion fees that may arise are borne by the client exclusively.</p>
                        </div>

                        <div className={s.subtitle}>
                        2.3 Additional charges
                        </div>
                        <div className={s.text_block}>
                            <p>The client is obliged to pay any additional costs incurred by Fedest or the logistics provider responsible that derives from any information inaccurately provided by the client throughout the ordering process or if the shipment’s details are not aligned with the terms, restrictions and limitations of the speciﬁc service booked as per these Terms and Conditions or the Terms and Conditions of the responsible logistics provider with which the client agrees when placing the order. Fedest reserves the right to demand the reimbursement of expenses from the client, covered by Fedest on behalf of the client, even if such expenses are invoiced by the logistics provider after the conclusion of the logistics service. Fedest reserves the right to charge an administrative fee of up to ﬁfteen (15) EUR for the handling of additional costs in such cases.</p>
                            <p>By accepting these Terms and Conditions, the client agrees that Fedest is allowed to make an additional surcharge directly to the payment method that has been used by the client for payment of the order. In case that Fedest would not be able to make additional charges automatically, the client will be notiﬁed of this in writing and the client must pay the additional amount indicated within a maximum period of seven (7) days. Any costs of the litigation and collection procedure are borne by the client.</p>
                            <p>Fedest reserves the right to charge the client in case that there is an abrupt change of pricing for the route selected by the client. In case that the client refuses the extra charge for the change of pricing in the selected route, Fedest reserves the right to offer to the client a full refund and not to forward the order for transportation services to any logistics provider.</p>
                            <p>If the shipment must be returned to the sender for any reason not pertaining to the side of the logistics provider, any additional costs that occur will be charged to the client and no additional insurance will be valid anymore. If the logistics service provider cannot return the shipment to the sender for any reason not pertaining to the side of the logistics service provider, the logistics service provider may destroy the shipment with no reimbursement to the client. All the costs related to the disposal of the shipment shall be borne by the client.</p>
                        </div>

                        <div className={s.subtitle}>
                        2.4 Cancellation and Refunds
                        </div>
                        <div className={s.text_block}>
                            <p>The client has the right to cancel an order in writing only. In case of the cancellation of the order by the client, the client agrees not to hand over any shipment to the logistics service provider if they arrive at the pick-up address. In case that the client does hand over the shipment, the client will be responsible for all the expenses occurred.</p>
                            <p>All orders are non-refundable after the order is considered as placed, i.e. it has been successfully paid or the client conﬁrmed the payment obligation. The costs of any extra services purchased in the ordering process as deﬁned for each shipping service type in the chapter 3 (such as but not limited to insurance fees, help with loading/unloading, provision of special equipment, exceeding of the declared size and weight of the shipment, etc) are non-refundable. Additional costs of refunding to the original payment method may occur.</p>
                        </div>

                        <div className={s.subtitle}>
                        2.5 Claims and complaints
                        </div>
                        <div className={s.text_block}>
                            <p>In case of damages or loss of a shipment during the performance of the logistics service the client has the right to initiate a claim procedure in order to receive a reimbursement for the loss of value incurred due to the damage or loss incident. Orders placed with Fedest are covered by the liability of the selected logistics provider as speciﬁed throughout the order placing process or Fedest directly. In addition to this the client may have an option of purchasing an additional insurance as speciﬁed in each of the shipping types in chapter 3. Any additional insurance policies offered in the ordering process are either offered by the logistics providers or by Fedest`s insurance company partners as an auxiliary service to the logistics service offer. The client understands that in case any reimbursement amount is approved based on the basic liability or the basic insurance included in the price and provided by the selected logistics provider, he can only claim the difference up to the maximum possible reimbursement amount as per the additional insurance policy purchased in the order placing process. The client understands that any additional insurance policy is based on the successfully established liability of the selected logistics provider and cannot otherwise be activated.</p>
                            <p>Fedest’s dedicated Claims department is responsible for deciding and conducting the claims on behalf of the clients against the logistics providers and/or insurance companies. The logistics providers or the insurance companies with existing agreements with the carriers or with Fedest shall provide Fedest with a decision on the admissibility of a claim within the timeframes speciﬁed in their Terms and Conditions. Not receiving a ﬁnal decision from third parties in due time shall not constitute any responsibility of Fedest. Fedest reserves the right to at any moment decline or reject the client’s claim due to incomplete, misleading, contradictory or false data received from the client. Fedest may reject to continue with the claims procedure with the logistics provider or insurance company in case the client breached these Terms and Conditions in any way. In such a case, Fedest will not recognize any further claims from the client or from third parties related to the client.</p>
                            <p>In case of damage, full or partial loss, the client must make a written statement on the proof of delivery and immediately (ﬁrst business day after the delivery) inform Fedest in order to start a claims process. The damage claim cannot be started in case of damages to the means of packaging (cardboard box, suitcase, etc.), but only to the content of the shipment. <b>Additionally, with a damage or loss claim, the insurance does not apply to the contents of the shipment in case of:</b></p>
                            <li>inappropriate packaging (including parcels bundled together and marked with only one label)</li>
                            <li>unconventional shipments</li>
                            <li>transportation of forbidden items</li>
                            <li>damages caused by a force majeure event</li>
                            <li>lack of evidence provided by the client that proves the condition of the parcel before collection or</li>
                            <li>in case the client violated the Terms and conditions of Fedest or the logistics provider in any way</li>
                            <p>Claims that are not supported by evidence of the true value of the damaged or lost item cannot be started. The only acceptable proof of value is the original invoice (VAT rate and amortization will be deducted from the original price of the item accordingly).</p>
                        </div>

                        <div className={s.subtitle2}>2.5.1 Damage claim</div>
                        <div className={s.text_block}>
                            <p>In order to initiate a damage claim procedure with Fedest, the client must inform Fedest of any damage incident on the date of delivery through the online contact form available at fedest.eu. If required Fedest will then provide further instructions to the client to acquire all the documents, pictures and other information necessary to start a claim against the logistics providers or the responsible insurance companies. The client is required to provide all the requested documentation to Fedest in a maximum of 2 business days after receiving the claim instructions. If the documentation necessary is provided later than in 2 business days, Fedest will not be held responsible for any claims rejected by the logistics providers and/or insurance companies due to not being started on time.</p>
                            <p>In case of damage of the shipment, the client is required to keep the shipment in the condition in which it was delivered, covering all expenses related, until the completion of the insurance procedure. If any item of the shipment is damaged on the outside, the client should take photographs of the damages before taking the goods out of the item of the shipment.</p>
                            <p>In case the client notices that some of the goods are missing from their shipment at the time of delivery, a claim procedure can be started. The client must provide Fedest with the same documentation as in the case of damage, including photographs of the shipment before collection and at the time of delivery that prove the item was opened/damaged during transport which could result in the missing content.</p>
                            <p>In case of damage, full or partial loss, the client must make a written statement on the consignment note, prepare the damage report and send it to Fedest. In case that no damage or apparent loss is noted at the time of delivery, Fedest reserves the right to decline or reject the client’s claim.</p>
                        </div>

                        <div className={s.subtitle2}>2.5.2 Loss claim</div>
                        <div className={s.text_block}>
                            <p>In case the shipment is not delivered within ﬁve (5) working days from the expected date of delivery, the client must immediately notify Fedest through the online contact form available at fedest.eu. Fedest client support team will then contact the responsible logistics provider and open a search investigation if necessary. If the responsible logistics provider will not be able to locate and deliver the shipment in due time, Fedest will inform the client that the shipment is ofﬁcially considered as lost and send instructions to the client to acquire all the documents, pictures and other information necessary to start a claim against the logistics providers and/or the responsible insurance companies.</p>
                            <p>The client is required to provide all the requested documentation to Fedest in a maximum of 2 business days after receiving the claim instructions. If the documentation necessary is provided later than in 2 business days, Fedest will not be held responsible for any claims rejected by logistics providers and/or insurance companies due to not being started on time.</p>
                            <p>In case that a claim is approved and the shipment is later found and delivered to the client successfully, the client is not entitled to the approved amount of reimbursement and is obliged to immediately return any reimbursement already received on the ground of the claim approved.</p>
                        </div>

                        <div className={s.subtitle2}>2.5.3 An Ofﬁcial Complaint</div>
                        <div className={s.text_block}>
                            <p>The client may raise an ofﬁcial complaint towards Fedest regarding any services provided to him via an online form on fedest.eu. <b>An ofﬁcial complaint procedure can only begin if all the necessary information (name of the client, Fedest order number, relevant information regarding the case) is provided to Fedest within seven (7) days after:</b></p>
                            <li>the last correspondence regarding a particular order; or</li>
                            <li>the last update of the shipment`s status in the tracking system; or</li>
                            <li>the order’s pick-up date; or</li>
                            <li>delivery date; considering which of these dates will be most recent.</li>
                            <p>Fedest will provide a reply to the client regarding the complaint no later than in thirty (30) days after the complaint is received.</p>
                        </div>

                        <div className={s.content_title}>
                            3. General rules of shipping
                        </div>
                        <div className={s.text_block}>
                            <p>The following general rules apply to all orders booked with Fedest unless it is clearly speciﬁed that part of the rules in section 3 of these Terms and Conditions apply only to a particular type of shipment/service type and/or a different rule applies to a particular service type as described under section 4 of these Terms and Conditions and/or in the Terms and Conditions of the speciﬁc carrier to which the customer expressly agrees before conﬁrming the order. It is the responsibility of the customer to be informed of the special rules of a particular service type they booked in the ordering process.</p>
                        </div>

                        <div className={s.subtitle}>
                        3.1. Packaging
                        </div>
                        <div className={s.text_block}>
                            <p>The client agrees that packaged goods must have a suitable outer and inner packaging, according to the type of items being transported. The packaging must be appropriate according to the weight and content of the shipment. The packaging must be suitable for transport and handling at the logistics centres of the logistics providers or their partners. The logistics provider may return the collected shipment or reject the collection of the shipment entirely due to inappropriately packed items and the client is solely responsible for all costs that may occur. The client is solely responsible for damages and losses of items packed improperly. The insurance claim may also be rejected if the shipment is considered dangerous and could damage other shipments during transportation.</p>
                            <p>Neither Fedest nor the logistics providers with which Fedest cooperates do not provide any special services to handle items that carry any sign, inscription or label which indicates breakable or fragile content or instructs careful handling of the item. The labels may be disregarded due to the automated handling of the shipments in the transportation process. The standard packaging of a shipment not containing pallets is a cardboard box, consisting of two or more layers of carton. Boxes need to be closed, secured with tape, and wrapped with foil if necessary. Any other type of packaging may be used only at client’s own risk and can lead to additional charges for the client. Torn, damaged or boxes with holes not provided by design must not be used. An adequate packaging must, among other characteristics, be resistant to a fall from a height of one (1) meter. Fedest is not responsible for any damage caused to the packaging during transport. Bags, suitcases and travel bags are not allowed types of packaging.</p>
                            <p>Before the pick-up, the client must remove all old labels or address information attached to the items. Solely the client is responsible for assuring that the right (i.e. correctly equipped with all the necessary information) label is placed on the right item of the shipment, even in cases where the logistics provider prints the label, and to acquire the proof of collection from the driver that proves the shipment was collected by the authorized logistics provider at the set date and time. In case of any issues Fedest is not obliged to accept any complaints or claims without the proof of collection.</p>
                            <p>For service types where shipping labels are required, each item of the shipment must be handed over to the driver of the selected logistics provider as one piece being equipped with one label. If the client intends to send more items, the accurate number must be placed when booking the logistics service. If the driver of the selected logistics provider collects more items than ordered, this does not constitute any obligation on the part of the logistics provider or Fedest.</p>
                        </div>

                        <div className={s.subtitle}>
                        3.2. Limitations of size and weight
                        </div>
                        <div className={s.text_block}>
                            <p>All prices offered to the client at the time of placing the order are based on the client`s declaration of the size and weight of their shipment. Any excess of the size and weight declared by the client in the ordering process will be subject to the procedure as per section 2.3. The client is obliged to check the logistics provider`s Terms and Conditions presented to him at the time of placing his order for any size and weight limitations that apply to the speciﬁc service booked. Any excess of the maximum weight and size allowed by the logistics provider may cause a refusal of collection or delivery by the logistics provider. Such a shipment may be returned to the sender at any time at the cost of the client. The client understands that the total ordered weight and dimensions include both, the packaging (i.e. parcel, wrapping, etc.) and the goods.</p>
                        </div>

                        <div className={s.subtitle}>
                        3.3. Pick-up and delivery
                        </div>
                        <div className={s.text_block}>
                            <p>The client agrees for the shipment to be available at the pick-up address for the selected logistics provider to collect it at any time during the daily working hours of the local logistics provider. Due to unforeseen factors related to the logistics provider, the pick-up can be performed before or after this time.</p>
                            <p>If the driver of the selected logistics provider did not pick-up the shipment on the pick-up date, the client, according to these Terms and Conditions, assumes the responsibility of informing Fedest immediately about this fact, so that Fedest can arrange with the selected logistics provider a second pick-up attempt. If the pick-up or delivery is not successful on the day scheduled for a reason not pertaining to the selected logistics provider, another request for collection or delivery may result in additional costs that shall be borne by the client.</p>
                            <p>The client is responsible for providing a complete and exact address for the pick-up and delivery of the shipment, and any other relevant information to facilitate the pick-up and delivery. The address details should ﬁt in the designated area in the booking process, and not be included in the “comments ﬁeld”; otherwise, the logistics providers might not receive the complete information needed for a successful pick-up and delivery.</p>
                            <ul><h6>The client understands that they are fully responsible for the following and needs to provide the correct details:</h6>
                            <li>The name and address of the sender (consignor);</li>
                            <li>The name and address of the consignee;</li>
                            <li>The description in the common use of the nature of the goods and the method of packing;</li>
                            <li>The number of packages and their special marks and numbers;</li>
                            <li>The gross weight of the goods or their quantity otherwise expressed.</li></ul>
                            <p>In case of wrong details provided, the client is holding full liability for costs which may occur.</p>
                            <p>The pick-up and delivery should be done in an easily accessible place; the driver of the selected carrier must be able to park the vehicle at least ﬁfteen (15) meters from the indicated address. The service is provided to the main door of the building. The driver is not obliged to pick up or deliver the shipment to any other ﬂoor than ground ﬂoor. Any unnecessary waiting due to unavailability of the sender or the shipment being unprepared for collection can cause a failed collection attempt which will be charged to the client. Any additional costs that occur will be processed as per the section 2.3.</p>
                            <p>Fedest does not guarantee transportation and shipment delivery to a different address than initially indicated by the client in the order process. Fedest can only inform the selected logistics provider about the changes to the address of delivery as per request of the client. Nor Fedest nor the logistics provider cannot be held responsible if the shipment is not delivered to an address provided after the label has been used for the shipment.</p>
                            <p>During the booking process, the customer must specify the local telephone number in the country of pick-up and delivery on which someone will be available at the time of pick-up and delivery. Only local telephone numbers can be contacted. While the valid local phone number should be provided, the driver of the selected carrier is not obliged to contact the user or the contact persons via phone.</p>
                            <p>The carrier may reject a collection in case that the service cannot be provided for any reason which is out of the carrier`s control such as remote locations or sanctions imposed to the pickup country (embargo). Fedest and the selected logistics service providers have the right to refuse the collection or delivery to the requested location when such a delivery could be harmful to persons, equipment, or other goods.</p>
                            <ul><h6>A shipment may be deemed unacceptable if:</h6>
                            <li>no customs declaration is made when required by applicable customs regulations;</li>
                            <li>it contains any prohibited items speciﬁed in section 3.4;</li>
                            <li>its address is incorrect or not properly marked or its packaging is defective or inadequate to ensure safe transportation with ordinary care in handling;</li>
                            <li>it contains any other item which the logistics provider decides cannot be carried safely or legally.</li></ul>
                            <p>If the shipment is deemed to be unacceptable, or it has been undervalued for customs purposes, or consignee cannot be reasonably identiﬁed or located, or the consignee refuses delivery or to pay customs duties or other shipment charges, the carrier shall use reasonable efforts to return the shipment to the shipper at the client`s cost, failing which the shipment may be released, disposed of without incurring any liability whatsoever to the client. The carrier may destroy any shipment which any law prevents it from returning to the shipper as well as any shipment of prohibited items.</p>
                            <p>Depending on the country and the logistics service provider there can be one or two attempts of delivery or even a delivery directly to the nearest access point managed by the selected logistics provider as deﬁned in their terms and conditions. Shipments cannot be delivered to PO boxes. Shipments are delivered to the recipient’s address given by the client but not necessarily to the named recipient’s personally. The recipient may be notiﬁed of an upcoming delivery or a missed delivery. The recipient may be given an option of alternative delivery options such as delivery on another day, no signature required, redirection or collection at the nearest access point. Surcharges for changes of delivery address may apply and will be treated as per the section 2.3.</p>

                            <ul><h6>According to these Terms and Conditions, the delivery is considered effective if:</h6>
                            <li>the shipment is handed over to any person claiming the shipment and located in the delivery address against digital or manual signature, including neighbours and persons present at the indicated premises;</li>
                            <li>the shipment is delivered to an alternative address (neighbouring address, local post ofﬁce or access point);</li>
                            <li>after multiple failed delivery attempts to the delivery address, the shipment is delivered in one of the warehouses of the selected carrier, which is near to the original delivery address. This may cause additional costs to the client;</li>
                            <li>the selected logistics provider has left a notice of delivery to the delivery contact person, or if this is evident through the shipment tracking;</li>
                            <li>the shipment is delivered to the delivery address to a person who falsely presented themselves as the recipient;</li>
                            <li>the shipment is returned back to the pick-up address after the maximum days in the carrier’s warehouse or due to inappropriate packaging or damage to the parcel or due to the parcel being overweight or oversized. This may cause additional costs to the customer.</li></ul>
                            <p>Exceptions regarding the deliveries of shipments may apply in certain regions due to conditions and standard practices of the local courier companies. The logistics provider has the right to open and inspect a shipment without notice for safety, security, customs or other regulatory reasons and shall incur no liability of any kind therefore. The logistics provider may also be required to undertake an X-ray screening of a shipment and the client hereby waives any possible claims for damages or delays as a result of screening. The client understands that all pick-up and delivery times provided in the order process are only estimated and not binding to Fedest. Fedest and the logistics provider are not obliged to compensate the client for any damages caused by any delay, neither are required to reimburse any related cost.</p>
                        </div>

                        <div className={s.subtitle}>
                        3.4. Prohibited items
                        </div>
                        <div className={s.text_block}>
                            <p>The client agrees with the list of items prohibited from transport herein and declares that they will not send any objects on this list, or any item that is prohibited by the Terms and Conditions of the selected logistics provider or by the laws of the country in which the pick-up or delivery address is located. If the client decides to send prohibited items anyway, they will be the solely responsible for all damages and expenses incurred.</p>
                            <ul><h6>Examples of prohibited items are:</h6>
                            <li>Dangerous goods, hazardous goods and ﬂammable goods classiﬁed as such, prohibited or restricted by IATA (International Air Transport Association), ICAO (International Civil Aviation Organization), or other relevant organization (“Dangerous Goods”);</li>
                            <li>Objects that can damage other items during the transport;</li>
                            <li>Antiques, furs;</li>
                            <li>Alcohol and tobacco products;</li>
                            <li>Liquids of any kind;</li>
                            <li>Human remains, live animals or plants, perishable food;</li>
                            <li>Any goods which require temperature-controlled transport;</li>
                            <li>Any goods prohibited by the law or regulation of any government or public or local authority of any country where the goods are carried;</li>
                            <li>Substances requiring special permits for transportation, special handling or speciﬁc documentation;</li>
                            <li>For airfreight: all Lithium Ion and Lithium Metal shipments; prohibited goods according to the regulation (EC) No 300/2008 of 11th March 2008 and its implementing rules as amended from time to time;</li>
                            <li>Shipments that would be covered by the European Agreement concerning the International Carriage of Dangerous Goods by Road (ADR).</li></ul>
                            <p>The logistics provider has the right to decline dealing with items whose content is of a dangerous or damaging nature. If such items are handed over to the selected logistics provider, it reserves the right to remove or otherwise deal with the items with no further notice at the expense of the client. In such a case, the client shall be liable for all loss or damage arising in connection to such items and shall indemnify the selected logistics provider and/or Fedest against all penalties, claims, damages, costs and expenses whatsoever arising in connection therewith, and the items may be dealt with in such a manner as the company or any other person in whose custody they may be at any relevant time shall think ﬁt.</p>
                            <ul><h6>In addition to the list of prohibited items, no insurance policy, unless expressly conﬁrmed by the carrier or by the insurance company concerned, will be accepted for the following items:</h6>
                            <li>food (including food in liquid form);</li>
                            <li>fragile items, such as glass and porcelain;</li>
                            <li>objects with sentimental value.</li></ul>
                        </div>

                        <div className={s.subtitle}>
                        3.5. Customs
                        </div>
                        <div className={s.text_block}>
                            <p>Fedest is not acting as a customs broker/agent and is not liable for the process or fees arising from customs duties or customs procedures unless otherwise speciﬁed within the relevant shipping service type in chapter 4. The client is always responsible to check the customs requirements of the export and import countries as per their local legislation and policies in advance.</p>
                            <p>The client is obliged to fully cooperate with the local customs authorities. Any customs duties, taxes (including but not limited to VAT if applicable), penalties, storage charges or other expenses are borne by the client. In case Fedest incurs any of such costs as a result of the actions of customs or other governmental authorities or the shipper’s or receiver’s failure to provide proper documentation, these costs will be charged to the client or the receiver of the shipment as well as any extra costs Fedest will incur. Such charges are processed as an additional charge as per section 2.3.</p>
                            <p>Fedest has no knowledge or responsibility to check or advise the client about the information and documents provided. The client agrees to indemnify Fedest and hold Fedest harmless from any claims that may be brought against Fedest arising from the false or wrong information provided to Fedest and any ﬁnancial sanctions that will be imposed to Fedest.</p>
                            <p>The client acknowledges that he has not relied on any statement, promise or representation made or given by or on behalf of the logistics service provider which is not set out in these terms and conditions.</p>
                            <ul>
                                <h6>For shipments from or to any country outside the EU, the client shall, in relation to each of the goods comprised in each shipment, provide the logistics service provider with:</h6>
                                <li>a commercial invoice in the English language, including but not limited to EORI number, a clear and unambiguous description of the goods, the value of the goods, currency, Incoterm used etc.;</li>
                                <li>a commodity code for each of the goods</li>
                                <li>a written notiﬁcation in advance for any items of the shipment and/or goods comprised within any item of the shipment which are not standard or permanent exports (for example, temporary exports such as repairs that will be returned to the country of origin);</li>
                                <li>any speciﬁc authorizations for direct representation of the client given to the logistics service provider if required under any applicable law; and</li>
                                <li>any information/document required by any relevant customs authorities, law and regulations or the logistics service provider.</li>
                            </ul>
                            <p>For the Customs or other formalities which have to be completed before the delivery of the goods, the client shall attach the necessary documents to the consignment note or place them at the disposal of the selected logistics provider and shall furnish him with all the information which he requires.</p>
                            <p>The client shall be responsible to check the relevant information required by the relevant customs authorities and all customs documents and the transportation document must be accurately completed by the client in compliance with all relevant customs laws, rules and regulations.</p>
                            <p>The logistics provider may reject the collection or even return the shipment back to the shipper, applying extra costs for which the client is solely responsible, in case that the documentation required is not provided or is not according to the regulations.</p>
                            <p>The shipper certiﬁes that all statements and information provided relating to the exportation and importation of the shipment will be true and correct and acknowledges that in the event that untrue or fraudulent statements about the shipment or any of its contents are made, a civil claim and/or criminal prosecution, the penalties for which include forfeiture and sale of the shipment, could arise.</p>
                        </div>

                        <div className={s.content_title}>
                            4. Service types
                        </div>
                        <div className={s.text_block}>
                            <p>The following section lists and describes the speciﬁc service types available within Fedet logistics solutions. Any speciﬁc rules should be treated as an add-on to the general rules described in the previous sections of these Terms and Conditions. In case of a conﬂict between this section and the previous sections, the speciﬁc service type rules as written in this section prevails.</p>
                        </div>

                        <div className={s.subtitle}>
                        4.1 Regular and Express shipping
                        </div>
                        <div className={s.subtitle2}>4.1.1 Deﬁnition</div>
                        <div className={s.text_block}>
                            <p>With the services of Regular and Express shipping the client may ship parcels worldwide by road or air, where Express shipping is the faster of the two services. The label is required to be printed and attached to each item individually.</p>
                        </div>

                        <div className={s.subtitle2}>4.1.2 Liability and additional insurance</div>
                        <div className={s.text_block}>
                            <p>Parcels in the Regular service are covered by the logistics provider’s liability as per the Convention on the International Carriage of Goods by Road Contract (CMR) or the Montreal Convention or the Warsaw Convention as applicable. The client may purchase an additional insurance with the logistics provider for an additional fee. The shipment will be insured for the amount of value declared by the client in the ordering process, being limited to the maximum possible insurance coverage of up to 50.000 EUR per shipment unless otherwise limited by the Terms and Conditions of the selected logistics service provider.</p>
                        </div>

                        <div className={s.subtitle}>
                        4.2 Other shipping types
                        </div>
                        <div className={s.text_block}>
                            <p>Fedest can individually offer other logistics services not described within the shipping types mentioned by these Terms and Conditions. In case the client wishes to book such services, the terms of shipping need to be clearly deﬁned and mutually agreed by both parties, in particular, by concluding a contract. If any terms of such shipping were not deﬁned and agreed, these Terms and Conditions shall apply. For placing the order the rules as described under section 2.1.1 “Ordering process” apply. Fedest may from time to time review the shipping types mentioned in these Terms and Conditions by adding new ones. Any new type of shipping becomes available to the client from the moment of publication at fedest.eu.</p>
                        </div>

                        <div className={s.content_title}>
                            5. Applicable Law and Jurisdiction
                        </div>
                        <div className={s.text_block}>
                        <ul>
                            <h6>Unless mandatory rules of the country in which the client is domiciled specify otherwise:</h6>
                            <li>these Terms and Conditions and the contracts to which these Terms and Conditions apply will be interpreted in light of the laws of the Kingdom of Spain;</li>
                            <li>all possible disputes arising from these Terms and Conditions or from the contracts to which these Terms and Conditions apply will be resolved in the courts of the Kingdom of Spain; and,</li>
                            <li>in case of differences in meaning between the various translations of these Terms and Conditions, the text originally written in English language shall prevail;</li>
                            <li>in case of differences between these Terms and Conditions and Terms and Conditions of logistics provider both documents shall be obeyed when it is possible: the strictest limitations should apply, and activities prohibited by at least one of these documents must be prohibited;</li>
                            <li>the invalidity or unenforceability of any provision shall not affect any other part of these Terms and Conditions.</li>
                            </ul>
                        </div>
                    </div>
           </div>
        </motion.div>
    );
};

export default TermsAndConditions;
