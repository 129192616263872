import s from './TrackingPopup.module.css'
import './index.css'
import parcelStatusOne from "../../../src/assets/img/parcel_status_one.svg"
import parcelStatusTwo from "../../../src/assets/img/parcel_status_two.svg"
import parcelStatusThree from "../../../src/assets/img/parcel_status_three.svg"
import parcelStatusFour from "../../../src/assets/img/parcel_status_four.svg"
import parcelStatusFive from "../../../src/assets/img/parcel_status_five.svg"
import parcelVerticalOne from "../../../src/assets/img/parcel_vertical_1.png"
import parcelVerticalTwo from "../../../src/assets/img/parcel_vertical_2.png"
import parcelVerticalThree from "../../../src/assets/img/parcel_vertical_3.png"
import parcelVerticalFour from "../../../src/assets/img/parcel_vertical_4.png"
import parcelVerticalFive from "../../../src/assets/img/parcel_vertical_5.png"
import classnames from "classnames";

export type ResponseType = {
    status: string; // статус доставки, вероятно от 1 до 4
    pickUpDate?: string; // дата забора груза
    pickUpLocation?: string; // местоположение забора груза
    deliveryDate?: string; // дата доставки
    deliveryLocation?: string; // место доставки
    pickUpPostCode?: string;
    deliveryPostCode?: string;
    weight?: string;
};

type PropsType = {
    response?: ResponseType
    getDeliveryInfo?: () => void
    error?: boolean
}

const TrackingPopup = (props: PropsType) => {

    console.log(props.response?.status)
    return (
        <div className={s.popup_wrapper}>
            <div className={s.content}>

                <div className={s.track}>
                    {
                        props.response?.status === "Order Received" && <img src={parcelStatusOne} alt=""/>
                    }

                    {
                        props.response?.status === "Order Picked Up" && <img src={parcelStatusTwo} alt=""/>
                    }

                    {
                        props.response?.status === "In Transit" && <img src={parcelStatusThree} alt=""/>
                    }

                    {
                        props.response?.status === "Out For Delivery" && <img src={parcelStatusFour} alt=""/>
                    }

                    {
                        props.response?.status === "Delivered" && <img src={parcelStatusFive} alt=""/>
                    }
                </div>

                <div className={s.title_block}>
                    Updates on the shipment status
                </div>
                <div style={{display: "flex"}}>
                    <div className={s.dates}>
                        <div>{props.response && props.response.pickUpDate}</div>
                        <div>{props.response && props.response.deliveryDate}</div>
                    </div>

                    {
                        props.response?.status === "Order Received" && <img src={parcelVerticalOne} alt=""/>
                    }

                    {
                        props.response?.status === "Order Picked_Up" && <img src={parcelVerticalTwo} alt=""/>
                    }

                    {
                        props.response?.status === "In Transit" && <img src={parcelVerticalThree} alt=""/>
                    }

                    {
                        props.response?.status === "Out For Delivery" && <img src={parcelVerticalFour} alt=""/>
                    }

                    {
                        props.response?.status === "Delivered" && <img src={parcelVerticalFive} alt=""/>
                    }

                    <div className={s.statuses_columns}>
                        <div>
                            <div className={classnames(s.status, props.response?.status === "Order Received" && s.active_status)}>Order Recieved</div>
                            <div className={s.address}>
                                {props.response?.pickUpLocation}&nbsp;
                                {props.response?.pickUpPostCode}
                            </div>
                        </div>
                        <div>
                            <div className={classnames(s.status, props.response?.status === "Order Picked_Up" && s.active_status)}>Order Picked Up</div>
                            <div className={s.address}>
                                From {props.response?.pickUpLocation}&nbsp;
                                {props.response?.pickUpPostCode}
                            </div>
                        </div>
                        <div>
                            <div className={classnames(s.status, props.response?.status === "In Transit" && s.active_status)}>In Transit</div>
                            <div className={s.address}>
                                From {props.response?.pickUpLocation}&nbsp;
                                {props.response?.pickUpPostCode}&nbsp;
                                — To {props.response?.deliveryLocation}&nbsp;
                                {props.response?.deliveryPostCode}
                            </div>
                        </div>
                        <div>
                            <div className={classnames(s.status, props.response?.status === "Out For Delivery" && s.active_status)}>Out For Delivery</div>
                            <div className={s.address}>
                                To {props.response?.deliveryLocation}&nbsp;
                                {props.response?.deliveryPostCode}
                            </div>
                        </div>
                        <div>
                            <div className={classnames(s.status, props.response?.status === "Delivered" && s.active_status)}>Delivered</div>
                            <div className={s.address}>
                                {props.response?.deliveryLocation}&nbsp;
                                {props.response?.deliveryPostCode}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TrackingPopup;
