import React from 'react';
import s from "../ContactsUs/Contacts.module.css";
import email from "../../../assets/img/email.svg"
import location from "../../../assets/img/location.svg"
import ContactsForm from '../../../components/ContactsForm/ContactsForm';

const Contacts = () => {
    return (
        <div className={s.wrapper}>
        <div className={s.containerConacts}>
            <div className={s.title_wrap}>
                <div className={s.title}>
                Contact Us
                </div>
                <div className={s.subtitle}>
                Any questions of remarks? Just write as a message!
                </div>
            </div>
        </div>
        <div className={s.bg_form_2}>
            <div className={s.info}>
                <div className={s.info_title}>Contact Information</div>
                <div className={s.info_wrap}>
                    <img src={email} />
                    <div className={s.info_text}>info@fedest.eu</div>
                </div>
                <div className={s.info_wrap}>
                    <img src={location} />
                    <div className={s.info_text}>Name of the company:
                    <br />Fedest Transport & Logistic, Sl</div>
                </div>
                <div className={s.info_wrap}>
                <div className={s.info_text1}>Company number:B66385063</div>
                </div>
                <div className={s.info_wrap}>
                <div className={s.info_text1}>Registered office address:
                <br />Calle Antoni Sedo' 80
                <br />08191 Rubi - Barcelona, Spain</div>
                </div>
            </div>
            <div className={s.subtitle_mobile}>
                Any questions of remarks? <span>Just write as a message!</span>
                </div>
            <ContactsForm/>
        </div>
    </div>
    );
};

export default Contacts;
