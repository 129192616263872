import React from 'react';
import styles from './Footer.module.css'
import {Link} from "react-router-dom";
import {
    WHY_US,
    CONTACT_US,
    TRACK,
    FAQ,
    PRIVACY_POLICY, TERMS_AND_CONDITIONS
} from "../../Constants/RoutesConstants";
import { handlerScrollUp } from '../../helpers/ScrollTo';
import ContactUsForm from '../ContactUsForm/ContactUsForm';

const Footer = () => {

    return (
        <>
            <footer className={styles.footer_wrapper}>
            <div className={styles.links_block_wrap}>
                <div className={styles.links_block_wrap1}>
                    <div className={styles.links_block_title}>Company details:</div>
                    <div>
                        <div className={styles.links_block_text}>Fedest Transport & Logistic, Sl</div>
                    </div>
                    <div>
                        <div className={styles.links_block_text}>CIF: B66385063</div>
                    </div>
                    <div>
                        <div className={styles.links_block_text}>Calle Antoni Sedo' 80
                        <br/>08191 Rubi - Barcelona, Spain</div>
                    </div>
                </div>
                <div className={styles.wrap_bottom}>
                    <div style={{marginRight: "100px"}}>
                        <div className={styles.copyright}>
                            © 2023 Fedest.eu <br/>
                            All Rights Reserved
                        </div>

                        <Link to="/">
                            <div className={styles.logo}></div>
                        </Link>
                    </div>
                </div>
            </div>
                    <div className={styles.wrap_blocks}>
                        <div className={styles.links_block1}>
                            <div>
                                <div className={styles.links_block_title}>About:</div>
                                <div className={styles.links_block}>
                                    <Link className={styles.footer_link} to={WHY_US} onClick={handlerScrollUp}>Why Us</Link>
                                    <Link className={styles.footer_link} to={FAQ} onClick={handlerScrollUp}>FAQ</Link>
                                    <Link className={styles.footer_link} to={CONTACT_US} onClick={handlerScrollUp}>Contacts</Link>
                                    <Link className={styles.footer_link} to={TRACK} onClick={handlerScrollUp}>Track your parcel</Link>
                                </div>
                            </div>
                            <div className={styles.privacy_wrap}>
                                <Link className={styles.footer_link2} to={PRIVACY_POLICY} onClick={handlerScrollUp}>Privacy Policy</Link>
                                <Link className={styles.footer_link2} to={TERMS_AND_CONDITIONS} onClick={handlerScrollUp}>Terms & Conditions</Link>
                            </div>
                        </div>
                        <div className={styles.links_block}>
                            <div className={styles.links_block_title}>our partners:</div>
                            <Link to={'https://www.ups.com/'} className={styles.footer_link} target='_blanck'>UPS</Link>
                            <Link to={'https://www.fedex.com'} className={styles.footer_link} target='_blanck'>FedEx</Link>
                            <Link to={'https://www.ferrarigroup.net'} className={styles.footer_link} target='_blanck'>Ferrari Express</Link>
                            <Link to={'https://www.dhl.com'} className={styles.footer_link} target='_blanck'>DHL</Link>
                        </div>
                    </div>
                    <div className={styles.links_block_wrap2}>
                        <div className={styles.links_block_title}>Company details:</div>
                        <div>
                            <div className={styles.links_block_text}>Fedest Transport & Logistic, Sl</div>
                        </div>
                        <div>
                            <div className={styles.links_block_text}>CIF: B66385063</div>
                        </div>
                        <div>
                            <div className={styles.links_block_text}>Calle Antoni Sedo' 80
                            <br/>08191 Rubi - Barcelona, Spain</div>
                        </div>
                    </div>
                    <div className={styles.links_block}>
                        <div className={styles.links_block_title}>Contact Us:</div>
                        <ContactUsForm/>
                    </div>
            </footer>
        </>

    );
};

export default Footer;
