import React, {useEffect, useState} from 'react';
import classnames from "classnames";
import styles from "../ContactUsForm/ContactUsForm.module.css";
import MyInput from "../ui/MyInput/MyInput";
import MyTextarea from "../ui/MyTextarea/MyTextarea";
import MyBtn from "../ui/MyBtn/MyBtn";
import {useFormik} from "formik";
import * as yup from "yup";
import weightIcon from '../../assets/img/weight_icon.svg'
import parcelIcon from '../../assets/img/parcel_icon.svg'
import CustomModal from "../ui/CustomModal/CustomModal";
import {SendFromSelect} from '../ui/MySelect/SendFromSelect';
import {SendToSelect} from '../ui/MySelect/SendToSelect';

const validationSchema = yup.object({
    name: yup.string().required('Required field'),
    phone: yup.string().required('Required field'),
    message: yup.string().required('Required field'),
    email: yup.string().email("invalid format").required('Required field'),
    cityFrom: yup.string().required('Required field'),
    cityTo: yup.string().required('Required field'),
    weight: yup.string().required('Required field'),
})

const ContactForm = () => {

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [countryFrom, setCountryFrom] = useState(sessionStorage.getItem("countryFrom") || "");
    const [cityFrom, setCityFrom] = useState(sessionStorage.getItem("cityFrom") || "");
    const [codeFrom, setCodeFrom] = useState(sessionStorage.getItem("codeFrom") || "");
    const [countryTo, setCountryTo] = useState(sessionStorage.getItem("countryTo") || "");
    const [cityTo, setCityTo] = useState(sessionStorage.getItem("cityTo") || "");
    const [codeTo, setCodeTo] = useState(sessionStorage.getItem("codeTo") || "");
    const [weight, setWeight] = useState(sessionStorage.getItem("weight") || "");

    useEffect(() => {
        const onStorageChange = (event: any) => {
            if (event.storageArea === sessionStorage) {
                switch (event.key) {
                    case 'countryFrom':
                        setCountryFrom(sessionStorage.getItem("countryFrom") || "");
                        break;
                    case 'cityFrom':
                        setCityFrom(sessionStorage.getItem("cityFrom") || "");
                        break;
                    case 'codeFrom':
                        setCodeFrom(sessionStorage.getItem("codeFrom") || "");
                        break;
                    case 'countryTo':
                        setCountryTo(sessionStorage.getItem("countryTo") || "");
                        break;
                    case 'cityTo':
                        setCityTo(sessionStorage.getItem("cityTo") || "");
                        break;
                    case 'codeTo':
                        setCodeTo(sessionStorage.getItem("codeTo") || "");
                        break;
                    case 'weight':
                        setWeight(sessionStorage.getItem("weight") || "");
                        break;
                }
            }
        };
        window.addEventListener('storage', onStorageChange);

        return () => {
            window.removeEventListener('storage', onStorageChange);
        };
    }, []);

    const contactForm = useFormik({
        initialValues: {
            countryFrom: sessionStorage.getItem("countryFrom") || "",
            cityFrom: sessionStorage.getItem("cityFrom") || "",
            codeFrom: sessionStorage.getItem("codeFrom") || "",
            countryTo: sessionStorage.getItem("countryTo") || "",
            cityTo: sessionStorage.getItem("cityTo") || "",
            codeTo: sessionStorage.getItem("codeTo") || "",
            weight: sessionStorage.getItem("weight") || "",
            name: "",
            email: "",
            phone: "",
            message: "",
        },
        validationSchema,
        onSubmit: async (values, formikHelpers) => {
            try {
                let response = await fetch('https://assembly.tehnik.tech/api/send_form/from', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        project: "fedest",
                        subject: "Parcel",
                        data: {
                            countryFrom: values.countryFrom,
                            cityFrom: values.cityFrom,
                            codeFrom: values.codeFrom,
                            countryTo: values.countryTo,
                            cityTo: values.cityTo,
                            codeTo: values.codeTo,
                            weight: values.weight,
                            name: values.name,
                            email: values.email,
                            message: values.message,
                            phone: values.phone,
                        }
                    })
                });
                console.log(response)
                setIsSuccessModalOpen(true);
                formikHelpers.setSubmitting(false);
                formikHelpers.resetForm();
            } catch (error) {
                setIsErrorModalOpen(true)
            } finally {

            }
        }
    })

    return (
        <>
            <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
                <div className={styles.popup_title}>
                    Success!
                </div>
                <div className={styles.popup_subtitle}>
                    Your message has been sent successfully.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn style={{padding: "20px 60px", marginTop: "40px"}} title={"Ok"}
                               onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
                <div className={styles.popup_title}>
                    Failed!!
                </div>
                <div className={styles.popup_subtitle}>
                    Something went wrong. Try again later or contact our support team.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn style={{padding: "20px 60px", marginTop: "40px"}} buttonType={"error"} title={"Ok"}
                               onClick={() => setIsErrorModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <div className={styles.form}>
                <div className={classnames(styles.gray_bg, styles.block)}>
                    <form onSubmit={contactForm.handleSubmit}>
                        <div className={styles.form}>
                            <div className={styles.wrap_select}>
                                <div className={styles.input_wrapper}>
                                    <SendFromSelect countryFrom={countryFrom}
                                                    cityFrom={cityFrom}
                                                    codeFrom={codeFrom}/>
                                </div>
                                <div className={styles.input_wrapper}>
                                    <SendToSelect countryTo={countryTo}
                                                  cityTo={cityTo}
                                                  codeTo={codeTo}/>
                                </div>
                            </div>
                            <div className={styles.wrap_select}>
                                <div className={styles.input_block}>
                                    <img src={parcelIcon} className={styles.icon}/>
                                    <div className={styles.my_input}
                                         style={{paddingTop: "15px"}}>Parcel
                                    </div>
                                </div>
                                <div className={styles.input_block}>
                                    <img src={weightIcon} className={styles.icon}/>
                                    <input
                                        className={styles.my_input}
                                        placeholder={"Weight (kg)"}
                                        name={'weight'}
                                        type='text'
                                        id={"weight"}
                                        value={weight}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.input_wrapper}>
                            <MyInput
                                id={"name"}
                                name={"name"}
                                placeholder='Name and Surname'
                                value={contactForm.values.name}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.name
                                && contactForm.errors.name
                                && <div className={styles.error_text}>{contactForm.errors.name}</div>
                            }
                        </div>

                        <div className={styles.input_wrapper}>
                            <MyInput
                                id={"phone"}
                                name={"phone"}
                                placeholder='Phone number'
                                value={contactForm.values.phone}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.phone
                                && contactForm.errors.phone
                                && <div className={styles.error_text}>{contactForm.errors.phone}</div>
                            }
                        </div>


                        <div className={styles.input_wrapper}>
                            <MyInput
                                id={"email"}
                                name={"email"}
                                placeholder='Email address'
                                value={contactForm.values.email}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.email
                                && contactForm.errors.email
                                && <div className={styles.error_text}>{contactForm.errors.email}</div>
                            }
                        </div>

                        <div className={styles.input_wrapper}>
                            <MyTextarea
                                id={"message"}
                                name={"message"}
                                placeholder='Message'
                                value={contactForm.values.message}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.message
                                && contactForm.errors.message
                                && <div className={styles.error_text}>{contactForm.errors.message}</div>
                            }
                        </div>

                        <div className={styles.contacts_button_wrapper}>
                            <div className={styles.button_block}>
                                <MyBtn title={"Send message"} large type={"submit"}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>

    );
};

export default ContactForm;
