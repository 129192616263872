import React, {useState} from 'react';
import s from './Home.module.css'
import {motion} from 'framer-motion';
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useFormik} from "formik";
import * as yup from "yup";
import ups from "../../../assets/img/ups.svg"
import fedex from "../../../assets/img/fedex.svg"
import dhl from "../../../assets/img/dhl.svg"
import ferrari from "../../../assets/img/ferrari.svg"
import weightIcon from '../../../assets/img/weight_icon.svg'
import parcelIcon from '../../../assets/img/parcel_icon.svg'
import {SendFromSelect} from '../../../components/ui/MySelect/SendFromSelect';
import MyInput from '../../../components/ui/MyInput/MyInput';
import { Link, useNavigate } from 'react-router-dom';
import { CONTACT } from '../../../Constants/RoutesConstants';
import { SendToSelect } from '../../../components/ui/MySelect/SendToSelect';

const validationSchema = yup.object({
    countryFrom: yup.string().required('Required field'),
    cityFrom: yup.string().required('Required field'),
    codeFrom: yup.string().required('Required field'),
    countryTo: yup.string().required('Required field'),
    cityTo: yup.string().required('Required field'),
    codeTo: yup.string().required('Required field'),
    weight: yup.string().required('Required field'),
})

const Home = () => {
    const navigate = useNavigate();
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    const handleSendFromChange = (value: string, fieldName: string) => {
        switch(fieldName) {
            case 'countryFrom':
                sendForm.setFieldValue("countryFrom", value);
                sessionStorage.setItem("countryFrom", value);
                break;
            case 'cityFrom':
                sendForm.setFieldValue("cityFrom", value);
                sessionStorage.setItem("cityFrom", value);
                break;
            case 'codeFrom':
                sendForm.setFieldValue("codeFrom", value);
                sessionStorage.setItem("codeFrom", value);
                break;
        }
    };

    const handleSendToChange = (value: string, fieldName: string) => {
        switch(fieldName) {
            case 'countryTo':
                sendForm.setFieldValue("countryTo", value);
                sessionStorage.setItem("countryTo", value);
                break;
            case 'cityTo':
                sendForm.setFieldValue("cityTo", value);
                sessionStorage.setItem("cityTo", value);
                break;
            case 'codeTo':
                sendForm.setFieldValue("codeTo", value);
                sessionStorage.setItem("codeTo", value);
                break;
        }
    };
    const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        sendForm.setFieldValue("weight", value);
        sessionStorage.setItem("weight", value);
    }


    const sendForm = useFormik({
        initialValues: {
            countryFrom: sessionStorage.getItem("countryFrom") || "",
            cityFrom: sessionStorage.getItem("cityFrom") || "",
            codeFrom: sessionStorage.getItem("codeFrom") || "",
            countryTo: sessionStorage.getItem("countryTo") || "",
            cityTo: sessionStorage.getItem("cityTo") || "",
            codeTo: sessionStorage.getItem("codeTo") || "",
            weight: sessionStorage.getItem("weight") || "",
        },
        validationSchema,
        onSubmit: async (values, formikHelpers) => {
            let response = await fetch('https://assembly.tehnik.tech/api/send_form/from', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    project: "fedest",
                    subject: "",
                    data : {
                        countryFrom: values.countryFrom,
                        cityFrom: values.cityFrom,
                        codeFrom: values.codeFrom,
                        countryTo: values.countryTo,
                        cityTo: values.cityFrom,
                        codeTo: values.codeTo,
                        weight: values.weight,
                    }})
            });
            navigate(CONTACT);
            let result = await response.json();

            if (result === 'ok') {
                setIsSuccessModalOpen(true)
                formikHelpers.resetForm()
            } else {
                setIsErrorModalOpen(true)
            }

        }
    })

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.main_container}>

                <div className={s.main_content_block}>
                    <ul className={s.subtitle_content}>
                      <li className={s.subtitle_wrap}>Cheap international parcel delivery</li>
                      <li className={s.subtitle_wrap}>Express parcel delivery</li>
                      <li className={s.subtitle_wrap}>Parcel delivery & courier services</li>
                    </ul>
                    <div className={s.title_block}>
                        <span className={s.title_color}>FEDEST — </span>
                        <div className={s.title_color}>transport & logistic service</div>
                    </div>
                </div>
            </div>

            <div className={s.subtitle}>
                    Quick checkout of your order
            </div>
                <form className={s.form_wrap} onSubmit={sendForm.handleSubmit}>
                    <div className={s.form}>
                    <div className={s.input_block}>
                        <SendFromSelect countryFrom={sendForm.values.countryFrom}
                                        cityFrom={sendForm.values.cityFrom}
                                        codeFrom={sendForm.values.codeFrom}
                                        onChange={handleSendFromChange}  />
                        {sendForm.errors.countryFrom && <div className={s.error}>{sendForm.errors.countryFrom}</div>}
                        {sendForm.errors.cityFrom && <div className={s.error}>{sendForm.errors.cityFrom}</div>}
                        {sendForm.errors.codeFrom && <div className={s.error}>{sendForm.errors.codeFrom}</div>}
                    </div>
                    <div className={s.input_block}>
                        <SendToSelect   countryTo={sendForm.values.countryTo}
                                        cityTo={sendForm.values.cityTo}
                                        codeTo={sendForm.values.codeTo}
                                        onChange={handleSendToChange} />
                        {sendForm.errors.countryTo && <div className={s.error}>{sendForm.errors.countryTo}</div>}
                        {sendForm.errors.cityTo && <div className={s.error}>{sendForm.errors.cityTo}</div>}
                        {sendForm.errors.codeTo && <div className={s.error}>{sendForm.errors.codeTo}</div>}
                    </div>
                    <div className={s.wrap_input_weight}>
                        <div className={s.input_block}>
                        <img src={parcelIcon} className={s.icon} />
                            <div className={s.my_input}
                            style={{paddingTop: "15px"}}>Parcel</div>
                        </div>
                        <div className={s.input_block}>
                        <img src={weightIcon} className={s.icon} />
                            <input
                                className={s.my_input}
                                onChange={handleWeightChange}
                                placeholder={"Weight (kg)"}
                                name={'weight'}
                                type='text'
                                id={"weight"}
                                value={sendForm.values.weight}
                            />
                            {sendForm.errors.weight && <div className={s.error}>{sendForm.errors.weight}</div>}
                        </div>
                    </div>
                    </div>
                    <Link className={s.link} to={CONTACT}>
                        <MyBtn title={"Get a quote"} large type={"submit"} disabled={!(sendForm.isValid && sendForm.dirty)} />
                    </Link>
                </form>


            <div className={s.map_block}>
                <div className={s.map_block_title}>How to send parcel?</div>
                <div className={s.map_block_title1}>Enter parcel basic data</div>
                <div className={s.map_block_title2}>Compare shipping rates and choose your best offer</div>
                <div className={s.map_block_title3}>Book shipment and wait for the courier</div>
                <div className={s.map_block_title4}>Track your parcel</div>
            </div>

            <div className={s.wrapper_partners}>
                <div className={s.subtitle}>Our Partners</div>
                <div className={s.text}>
                These exceptional companies have been instrumental in supporting us and collaborating with us to achieve our shared goals
                </div>
                <div className={s.description_wrap}>
                    <div className={s.description_title}>
                        <img className={s.description_img} src={ups}></img>
                        <span className={s.description_subtitle}>United Parcel Service (UPS)</span>
                    </div>
                    <div className={s.description_title}>
                        <img className={s.description_img} src={fedex}></img>
                        <span className={s.description_subtitle}>FedEx</span>
                    </div>
                    <div className={s.description_title}>
                        <img className={s.description_img} src={dhl}></img>
                        <span className={s.description_subtitle}>DHL</span>
                    </div>
                    <div className={s.description_title}>
                        <img className={s.description_img} src={ferrari}></img>
                        <span className={s.description_subtitle}>Ferrari Group</span>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Home;
