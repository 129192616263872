import s from './App.module.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/PublicPages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {
    WHY_US,
    CONTACT_US,
    TRACK,
    FAQ,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
    CONTACT,
} from "./Constants/RoutesConstants";
import ContactsUs from "./pages/PublicPages/ContactsUs/ContactsUs";
import Faq from "./pages/PublicPages/Faq/Faq";
import WhyUs from "./pages/PublicPages/WhyUs/WhyUs";
import TermsAndConditions from "./pages/PublicPages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PublicPages/PrivacyPolicy/PrivacyPolicy";
import TrackParsel from "./pages/PublicPages/TrackParsel/TrackParsel";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import Contacts from './pages/PublicPages/Contacts/Contacts';

function App() {

    return (
        <div className={s.app}>

            <div className={s.desktop_header}>
                <Header/>
            </div>

            <div className={s.mobile_header}>
                <MobileHeader/>
            </div>

            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={WHY_US} element={<WhyUs/>}/>
                <Route path={CONTACT_US} element={<ContactsUs/>}/>
                <Route path={CONTACT} element={<Contacts/>}/>
                <Route path={TRACK} element={<TrackParsel/>}/>
                <Route path={FAQ} element={<Faq/>}/>
                <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions/>}/>
                <Route path={PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
            </Routes>


        <Footer/>
      </div>
  );
}

export default App;
