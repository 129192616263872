import React from 'react';
import s from './WhyUs.module.css'
import {motion} from 'framer-motion';
import classnames from 'classnames';

const WhyUs = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.main_text_block}>
                        <div className={s.main_title}>
                        Why choose us
                        </div>
                        <div className={s.main_text}>
                        The best shipment suppliers, providing the opportunity and needs to find the most suitable supplier and send profitably! Currently, our partners are UPS, FedEx, Ferrari Group and DHL.
                        </div>
                    </div>
                </div>
                <div className={s.content_block}>
                    <div className={s.title_block}>
                        <span>Our values — </span> <span className={s.title_color}>convenience, price </span><span
                        className={s.title_color2}>&</span><span className={s.title_color}> quality</span>
                    </div>
                    <div className={s.line_block}>
                        <div className={s.line_block1}>
                            <div className={s.line_block1_wrap}>
                                <div className={s.line_block_title}>Compare</div>
                                <div className={s.line_block_text}>Compare the services of several shipment suppliers quickly and easily, choosing the most advantageous supplier.</div>
                            </div>
                            <div className={s.line_block1_wrap}>
                                <div className={s.line_block_title}>Send</div>
                                <div className={s.line_block_text}>Make domestic and international shipments as a private or legal entity. Send an envelope and package.</div>
                            </div>
                            <div className={s.line_block1_wrap} style={{marginTop: 0, marginBottom: "50px"}}>
                                <div className={s.line_block_title}>Follow</div>
                                <div className={s.line_block_text}>You will receive a tracking number for each shipment so you can track it online.</div>
                            </div>
                        </div>
                        <div className={s.line_block2}></div>
                        <div className={s.line_block3}>
                            <div className={s.line_block3_wrap}>
                                <div className={s.line_block_title}>Save</div>
                                <div className={s.line_block_text2}>Save time and money — make shipment at any time of the day without service charge. Save up to 50% on the price of service and receive an invoice immediately.</div>
                            </div>
                            <div className={s.line_block3_wrap}>
                                <div className={s.line_block_title}>Trust</div>
                                <div className={s.line_block_text2}>Entrust your shipments to Fedest.eu — we will find the best transport and logistic solutions for you.</div>
                            </div>
                        </div>
                    </div>
                    <div className={s.line_mobile_wrap}>
                        <div className={s.line_block_mobile}></div>
                        <div className={s.text_block_mobile}>
                            <div className={s.line_block1_wrap}>
                                <div className={s.line_block_title}>Compare</div>
                                <div className={s.line_block_text}>Compare the services of several shipment suppliers quickly and easily, choosing the most advantageous supplier.</div>
                            </div>
                            <div className={s.line_block3_wrap}>
                                <div className={s.line_block_title}>Save</div>
                                <div className={s.line_block_text2}>Save time and money — make shipment at any time of the day without service charge. Save up to 50% on the price of service and receive an invoice immediately.</div>
                            </div>
                            <div className={s.line_block3_wrap}>
                                <div className={s.line_block_title}>Trust</div>
                                <div className={s.line_block_text2}>Entrust your shipments to Fedest.eu — we will find the best transport and logistic solutions for you.</div>
                            </div>
                            <div className={s.line_block1_wrap}>
                                <div className={s.line_block_title}>Send</div>
                                <div className={s.line_block_text}>Make domestic and international shipments as a private or legal entity. Send an envelope and package.</div>
                            </div>
                            <div className={s.line_block1_wrap}>
                                <div className={s.line_block_title}>Follow</div>
                                <div className={s.line_block_text}>You will receive a tracking number for each shipment so you can track it online.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default WhyUs;
