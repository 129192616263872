import React from 'react';
import {motion} from "framer-motion";
import s from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.main_text_block}>
                        <div className={s.title}>
                        Privacy Policy
                        </div>
                    </div>
                </div>

                    <div className={s.content}>
                    <div className={s.text_block}>
                            <p>
                            Fedest Transport & Logistic SL is committed to protect and respect your privacy within the scope of the utilisation of our website, as we consider the protection of data as a key pillar to building trust in strong and long-lasting relations. This Privacy Policy aims to inform you of which of your personal data we may collect, for which purposes we may use it and which rights you are entitled to.                            
                            </p>
                            <p>
                            In compliance with applicable data protection legislation, we inform you herewith that we are the controller of the personal information collected, received or otherwise processed about you as described below. Such legislation includes Regulation (EU) 2016/679 of April 27th 2016 (GDPR) and any other national or supra-national statutory law applicable to us. The responsible party in terms of the GDPR within the scope of this website is Fedest Transport & Logistic SL, a company with registered ofﬁce situated at Calle Antoni Sedo 80, 08191 Rubi, Spain and registered with the Spanish register of commerce and companies under number B66385063 («Fedest», «we», «our», «us»). In its capacity as data controller, Fedest is responsible to ensure that the personal data is being processed in a correct manner and in accordance with applicable legislation.                            </p>
                            <p>
                            Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. If you do not agree with this policy, do not access or use our Services or interact with any other aspect of our business.                            
                            </p>
                            <p>
                            By placing an order or asking for a quote you are accepting and consenting to the practices described in this policy. Furthermore, you guarantee that all persons, whose personal information is given to us by you related to your order or your account, are aware of this policy, accept and consent to the practices described in this policy and are willing and able, upon request of the Information Commissioner or any other Data Protection Authority, to provide a written proof of their consent and acceptance.
                            </p>
                        </div>

                        <div className={s.text_block}>
                            <div className={s.content_title}>
                            1. Information we may collect from you
                            </div>
                            <p>We may collect and process the following data about you:</p>
                        </div>

                        <div className={s.subtitle}>
                        1.1 Information you give us
                        </div>
                        <div className={s.text_block}>
                            <p>You may give us information about yourself by ﬁlling in forms on fedest.eu (our website) or by corresponding with us by phone, e-mail or otherwise. This includes information you provide when you register to use our site, subscribe to our services, place an order on our site, other activities commonly carried out on the site and when you report a problem with our site. The information you give us may include:</p>
                            <li>information about the customer: your name (your ﬁrst name and surname or name of the company and ﬁrst name and surname of the person in charge), VAT ID, address and phone number, ﬁnancial and credit card information;</li>
                            <li>information related to the pick-up: (ﬁrst name and surname of the person handing in the package, telephone number, pick-up address);</li>
                            <li>information related to the delivery: (ﬁrst name and surname of the person to which the package will be delivered, telephone number and delivery address);</li>
                            <li>information related to the conﬁrmation: (email address);</li>
                            <li>information you provide through our support channels, where you may choose to submit information regarding a problem you are experiencing with a shipment. Whether you speak to one of our representatives directly or otherwise engage with our support team, you will provide to us contact information, a summary of the problem you are experiencing, and any other documentation, screenshots or information that would be helpful in resolving the issue, including type of device, operating system, browser type, IP address, URLs of referring/exit pages, device identiﬁers, and crash data.</li>
                        </div>
                        
                        <div className={s.subtitle}>
                        1.2 Information we collect automatically when you use the Services
                        </div>
                        <div className={s.text_block}>
                            <p>We collect information about you when you use Fedest services, including while you are browsing our websites.</p>
                            <li>We keep track of certain information about you when you visit and interact with our services. This information includes the features you use; </li>
                            <li>the services you select, payment options preferred, frequently used addresses, links you click on.</li>
                            <li>Cookies and other technologies: we use them in order to provide functionality and to recognize you when you’re trying to use our services.</li>
                        </div>

                        <div className={s.subtitle}>
                        1.3 Information we receive from other sources
                        </div>
                        <div className={s.text_block}>
                            <p>We may receive information about you if you use other services we provide or from public registers. We are also working closely with third parties (including, for example, carriers, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them.</p>
                        </div>

                        <div className={s.content_title}>
                            2. Period of retention
                        </div>
                        <div className={s.text_block}>
                            <p>It is our aim to process your personal data to the least extent possible. If the exact storage periods are not mentioned in this statement, we will only store your personal data as long as it is necessary to fulﬁl the purpose for which it was originally collected and, if appropriate, as long as legally stipulated. When the processing of the personal data is no longer necessary for the purpose of which it was collected, we will erase such personal data.</p>
                        </div>

                        <div className={s.content_title}>
                            3. Purposes of the processing
                        </div>
                        
                        <div className={s.subtitle}>
                        3.2 Information you give to us relating to the pick-up, the delivery and to the conﬁrmation
                        </div>
                        <ul className={s.text_block}><h6>We will use this information in order to:</h6>
                            <li>carry out our obligations arising from any contracts entered into between you and us (including forwarding this information to the carriers within EEA to organize and execute transportation);</li>
                            <li>provide you with the information, products and services that you request from us.</li>
                            </ul>
                            
                        <div className={s.subtitle}>
                        3.3 Information we receive from other sources
                        </div>
                        <div className={s.text_block}>
                            <p>We may combine this information with information you give to us. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).</p>
                        </div>

                        <div className={s.content_title}>
                            4. Disclosure of your information
                        </div>
                        <div className={s.text_block}>
                            <p>Depending on the nature and scope of our assignment or the services requested from us, we may share personal information with the following recipients to the extent that such disclosure or transmission is deemed reasonably necessary or desirable for satisfying the purposes mentioned above or at the speciﬁc legitimate request of our clients, the data subjects concerned. </p>
                        </div>
                        <ul className={s.text_block}><h6>We may share your personal data with:</h6>
                            <li>authorized Fedest personnel located within EEA countries that need access to the information for the performance of any contract we enter into with you;</li>
                            <li>companies part of the Fedest group in order to operate and improve products and services;</li>
                            <li>selected third parties including carriers and other business partners, suppliers and sub-contractors (all within EEA countries) for the performance of any contract we enter into with them or you, including: carriers, providers of website and application development, hosting, maintenance, backup, storage, virtual infrastructure, payment processors, accounting ﬁrms, CRM platform providers, phone system providers, analytics systems providers, etc.</li>
                        </ul>
                        <p>When choosing third parties to whom we may share your information, Fedest takes all the necessary precautions to ensure their adequate level of protection with regards to personal data.</p>
                        <ul className={s.text_block}><h6>We may also disclose your personal information to third parties:</h6>
                            <li>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets, but only in case that it is strictly necessary for the transaction to be concluded.</li>
                            <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions (fedest.eu/en/terms-conditions) and other agreements; or to protect the rights, property, or safety of Fedest, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
                            <p>Fedest would like to point out that it takes its duties towards customers, partners and personnel seriously; hence, does not disclose data to anyone, unless it is required to do so in order to comply with the law or a valid and binding order of a government or regulatory body, or in order to fulﬁl a contractual obligation for which the data subject has given consent to the processing (such as cases mentioned above). In such cases, the government or regulatory bodies need to follow the applicable legal process to obtain valid and binding orders, and such orders are reviewed by Fedest, which may object to too broad or otherwise inappropriate or inadequate orders.</p>
                            <p>Unless prohibited from doing so, or if there is a clear indication of illegal conduct in connection with the use of Fedest’s products or services, Fedest gives a notice to its customers before disclosing a customer’s contact, so that they can seek and prepare protection from disclosure.</p>
                            <p>Fedest also takes serious precautions with regard to transfers outside of the EEA. Fedest commits to not transferring data outside of the EEA, unless that country or territory also ensures an adequate level of protection or appropriate safeguards, and under the condition that the enforceable data subject rights and effective legal remedies for data subjects are available.</p>
                        </ul>
                            
                        
                        <div className={s.content_title}>
                            5. Legal basis
                        </div>
                        <p>In order to be a lawful processing, a controller must always base its processing activities of personal data on one of the legal grounds enumerated within the GDPR. We will process your personal data only when we have an appropriate legal basis for doing so.</p>
                        <div className={s.text_block}>
                        <ul>
                            <h6>Your personal data shall be processed when:</h6>
                            <p>1. you have given your consent;</p>
                            <p>2. it is necessary for the performance of a contract to which you are a party or in order to take steps at your request before entering into a contract;</p>
                            <p>3. it is necessary for compliance with a legal obligation to which we are subject;</p>
                            <p>4. it is necessary for the purposes of our legitimate interests as long as our interests are not overridden by your interests or fundamental rights and freedoms. <b>For instance:</b></p>
                            <li>to establish, exercise or defend our legal rights in case of a legal claim;</li>
                            <li>to maintain and update our list of contacts;</li>
                            <li>to deal with communications received from you, contacts via phone or email, and responding to your queries;</li>
                            <li>to ensure network and information security and stability.</li>
                            </ul>
                        </div>

                        <div className={s.content_title}>
                            6. Your rights & Remedies
                        </div>
                        <div className={s.subtitle}>
                        6.1 Right to access
                        </div>
                        <div className={s.text_block}>
                            <p>You have the right to obtain from us conﬁrmation as to whether or not personal information concerning you is being processed, and, where that is the case, access the personal information and relevant information in that regard.</p>
                        </div>
                        <div className={s.subtitle}>
                        6.2 Right to correction
                        </div>
                        <div className={s.text_block}>
                            <p>You have the right to obtain from us without undue delay the rectiﬁcation of inaccurate personal information concerning you, the right to have incomplete personal information completed.</p>
                        </div>
                        <div className={s.subtitle}>
                        6.3 Right to object
                        </div>
                        <div className={s.text_block}>
                            <p>You have the right to object to the processing of your personal data in certain circumstances stated by GDPR.</p>
                        </div>
                        <div className={s.subtitle}>
                        6.4 Right to erasure (Right to be forgotten)
                        </div>
                        <div className={s.text_block}>
                            <p>You have the right to be forgotten whenever the original purposes are no longer relevant and that we do not have legitimate grounds to decline your request to be forgotten.</p>
                        </div>
                        <div className={s.subtitle}>
                        6.5 Right to request the restriction of processing
                        </div>
                        <div className={s.text_block}>
                            <p>If you do not agree with a decision to process personal data based on our legitimate interest you may request that such processing be restricted. You may also request a restriction of processing if you consider that we process incorrect data or that the personal data is no longer necessary for the purposes of processing.</p>
                        </div>
                        <div className={s.subtitle}>
                        6.6 Right to transportability
                        </div>
                        <div className={s.text_block}>
                            <p>You may ask for the transfer of your personal data to another party.</p>
                        </div>
                        <div className={s.subtitle}>
                        6.7 Right to withdraw your consent
                        </div>
                        <div className={s.text_block}>
                            <p>You have the right to withdraw your consent at any time when the processing of personal data was based on your consent.</p>
                        </div>
                        <div className={s.subtitle}>
                        6.8 Right to lodge a complaint with the supervisory authority
                        </div>
                        <div className={s.text_block}>
                            <p>You have the right to ﬁle a complaint with the data protection authority in your country. Nevertheless, we invite you to contact us before making any complaint to the competent authority.</p>
                            <p><b>To exercise your rights, you can send a written request to info@fedest.eu</b></p>
                        </div>

                        <div className={s.content_title}>
                            7. Notiﬁcation of breach
                        </div>
                        <div className={s.text_block}>
                            <p>We will notify the National Data Protection Commission in case of a breach of personal data without undue delay and no later than 72 hours after detecting the breach. Pursuant to the provisions of GDPR, clients and contacts will be personally notiﬁed of a personal data breach, without undue delay, only when the personal data breach is likely to result in a high risk to their rights and freedoms.</p>
                        </div>

                        <div className={s.content_title}>
                            8. Changes to our privacy policy
                        </div>
                        <div className={s.text_block}>
                            <p>Any changes we may make to our Privacy Policy in the future will be posted on this page and, where appropriate, notiﬁed to you by e-mail. We encourage you to review regularly this page to remain informed about your rights and the way we process your personal data.</p>
                            <p><b>This Policy was last reviewed and updated on 23th of October 2023.</b></p>
                        </div>

                        <div className={s.content_title}>
                            9. Other websites
                        </div>
                            <div className={s.text_block}>
                            <p>Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and afﬁliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>
                        </div>

                        <div className={s.content_title}>
                            10. Contact
                        </div>
                        <div className={s.text_block}>
                            <p>Questions, comments, complaints and requests regarding this privacy policy are welcomed and should be addressed to <b>info@fedest.eu</b></p>
                            <p><b>This version of the Terms and Conditions enters into force on November 1st 2023.</b></p>
                        </div>
                    
                    </div>
           </div>
        </motion.div>
    );
};

export default PrivacyPolicy;
