import React, {useState} from 'react';
import {motion} from "framer-motion";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import {useFormik} from "formik";
import * as yup from "yup";
import s from './TrackParsel.module.css'
import track from '../../../assets/img/icon_track.svg'
import TrackingPopup, {ResponseType} from '../../../components/TrackingPopup/TrackingPopup';




const TrackParsel = () => {
    const [isShowTrack, setIsShowTrack] = useState(false)
    const [parcel, setParcel] = useState<ResponseType>()

    const trackForm = useFormik({
        initialValues: {
            trackNumber: "",
        },
        onSubmit: async (values, formikHelpers) => {
            let response = await fetch('https://admonfed.dataprotect.site/api/delivery/info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    code: values.trackNumber
                })
            });
            const result = await response.json()
            setParcel(result)
            setIsShowTrack(true)
        }
    })

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.main_text_block}>
                        <div className={s.main_title}>
                            Track your parcel
                        </div>
                        <div className={s.main_text}>
                            Here you can find the delivery status of your parcel
                        </div>
                    </div>
                </div>
                <div className={s.content_block}>
                    <div className={s.title_block}>
                        Please fill the form with the tracking number of your shipment
                    </div>
                </div>
                <form className={s.form_wrap} onSubmit={trackForm.handleSubmit}>
                    <div className={s.form}>
                        <div className={s.input_block}>
                            <img src={track} className={s.icon}/>
                            <input
                                name={"trackNumber"}
                                id={"trackNumber"}
                                type="text"
                                placeholder={"Enter your tracking number"}
                                className={s.my_input}
                                value={trackForm.values.trackNumber}
                                onChange={trackForm.handleChange}
                            />
                            {trackForm.errors.trackNumber &&
                                <div className={s.error}>{trackForm.errors.trackNumber}</div>}
                        </div>
                    </div>
                    <MyBtn title={"Search"} large type={"submit"}
                        //onClick={() => setIsShowTrack(true)}
                    />
                </form>
                {isShowTrack && <TrackingPopup response={parcel}/>}
            </div>
        </motion.div>
    );
};

export default TrackParsel;
