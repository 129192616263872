import React from 'react';
import s from './NavBar.module.css'
import {Link} from "react-router-dom";
import classnames from "classnames";
import closePopup from "../../../assets/img/close.svg"
import logo from "../../../assets/img/logo.svg"
import MyBtn from "../../ui/MyBtn/MyBtn";
import {
    WHY_US,
    CONTACT_US,
    FAQ,
    TRACK,
} from "../../../Constants/RoutesConstants";

type propsType = {
    isActive: boolean
    setIsActive: (boolean: boolean) => void
}
const NavBar = ({isActive, setIsActive}: propsType) => {

    const closeBurgerMenu = () => {
        setIsActive(false);
      };

    return (
        <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
            <div className={s.close_img_block}>
                <Link to="/" onClick={closeBurgerMenu}>
                    <img src={logo} alt="" className={s.mobile_logo}/>
                </Link>
                <img src={closePopup} className={s.closeImg} alt="" onClick={() => setIsActive(false)}/>
            </div>
            <div className={s.links_block}>
                <Link className={s.link} to={WHY_US} onClick={closeBurgerMenu}>Why Us</Link>
                <Link className={s.link} to={FAQ} onClick={closeBurgerMenu}>FAQ</Link>
                <Link className={s.link} to={CONTACT_US} onClick={closeBurgerMenu}>Contact Us</Link>
            </div>
            <div className={s.buttons_block} onClick={closeBurgerMenu}>
                <Link to={TRACK}>
                    <MyBtn title={"Track your parcel"} style={{width: "180px"}}/>
                </Link>
            </div>
        </div>
    );
};

export default NavBar;
