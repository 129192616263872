import React from 'react';
import s from "./Faq.module.css";
import {motion} from 'framer-motion';
import {Collapse} from 'antd';
import minus from '../../../assets/img/minus.svg'
import plus from '../../../assets/img/plus.svg'

const {Panel} = Collapse;

const Faq = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.wrapper}>
                <div className={s.container_bg}>
                    <div className={s.title_wrap}>
                        <div className={s.title}>
                            Questions? Look here.
                        </div>
                        <div className={s.subtitle}>
                            Can’t find an answer? Contact us info@fedest.eu
                        </div>
                    </div>
                </div>
                <div className={s.container}>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"start"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"Who will be delivering parcels?"} key={1}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>
                            Fedest.eu partnered with following international companies to deliver your parcels: UPS, FedEx, Ferrari Group and DHL.
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"start"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"Who can ship?"} key={2} className={s.panelStyle}>
                            <div className={s.collapse_text}>
                            Delivery services can be purchased by both individuals and legal entities
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"start"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"Why should I choose Fedest.eu?"} key={3}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>
                            On our website you can safely organise delivery for your parcels. Save time and money by comparing various delivery partners in one place. Choose best deal.
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"start"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"What shipments can I send using Fedest.eu?"} key={4}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>
                            Fedest.eu allows both individuals and legal entities to send letters and parcels.
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"start"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"How to pack a shipment?"} key={5}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>
                            The shipment must be packed safely and correctly - so that it is not damaged during transportation, does not damage other shipments or the supplier's transport. When choosing a package, the content, weight and shape of the consignment must be taken into account - it must cover the entire contents of the consignment. The packaging must be durable and the mode and duration of transport must be taken into account during shipment. The safest packaging is cardboard, boxes and secure envelopes that are used for the first time. The packaging must always be sealed with adhesive tape or string. Each supplier has its own packaging rules. Before making a shipment, please read them.
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"start"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"What is a FRAGILE shipment?"} key={6}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>
                            A fragile shipment is fragile, fine items such as glass, ceramics, electrical appliances, and more. The shipment must be accompanied by an additional service "TRAUSLS" - this guarantees manual sorting. It is the sender's responsibility to mark the shipment with the appropriate mark. (available here). The additional service "TRAUSLS" does not replace careful packaging.
                            </div>
                        </Panel>
                    </Collapse>

                    <Collapse
                        className={s.my_collapse}
                        expandIconPosition={"start"}
                        bordered={false}
                        expandIcon={({isActive}) => isActive ?
                            <img className={s.icon_minus} src={minus} alt=""/> :
                            <img src={plus} className={s.icon_plus} alt=""/>}
                        style={{
                            background: "transparent",
                            alignItems: 'center',
                        }}
                    >
                        <Panel header={"When is courier coming?"} key={7}
                               className={s.panelStyle}>
                            <div className={s.collapse_text}>
                                After you have completed and paid for your order, we will inform you when the courier arrives, day and time.
                            </div>
                        </Panel>
                    </Collapse>

                </div>
            </div>
        </motion.div>

    );
};

export default Faq;
