import React from 'react';
import style from './header.module.css'
import {Link} from "react-router-dom";
import {
    WHY_US,
    CONTACT_US,
    FAQ,
    TRACK,
} from "../../Constants/RoutesConstants";
import MyBtn from "../ui/MyBtn/MyBtn";

const Header = () => {

    return (
        <header className={style.container}>
            <div className={style.block}>

                <div className={style.logo_block}>
                    <Link className={style.header_link} to="/">
                        <div className={style.logo}></div>
                    </Link>
                </div>

                <div className={style.links_block}>
                    <Link className={style.header_link} to={WHY_US}>Why Us</Link>
                    <Link className={style.header_link} to={FAQ}>FAQ</Link>
                    <Link className={style.header_link} to={CONTACT_US}>Contacts</Link>
                </div>
            </div>
                    <Link to={TRACK}>
                        <MyBtn title={"Track your parcel"}/>
                    </Link>
        </header>
    );
};

export default Header;
