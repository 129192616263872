import React, {useEffect, useState} from 'react';
import classnames from "classnames";
import styles from "./ContactUsForm.module.css";
import MyInput from "../ui/MyInput/MyInput";
import MyTextarea from "../ui/MyTextarea/MyTextarea";
import MyBtn from "../ui/MyBtn/MyBtn";
import {useFormik} from "formik";
import * as yup from "yup";
import CustomModal from "../ui/CustomModal/CustomModal";

const validationSchema = yup.object({
    name: yup.string().required('Required field'),
    phone: yup.string().required('Required field'),
    message: yup.string().required('Required field'),
    email: yup.string().email("invalid format").required('Required field'),
})

const ContactUsForm = () => {

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    const contactForm = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            message: "",
        },
        validationSchema,
        onSubmit: async (values, formikHelpers) => {
            console.log("here")

            let response = await fetch('https://assembly.tehnik.tech/api/send_form/from', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    project: "fedest",
                    subject: "Parcel",
                    data : {
                        name: values.name,
                        email: values.email,
                        message: values.message,
                        phone: values.phone,
                    }})
            });

            let result = await response.json();

            if (result === 'ok') {
                setIsSuccessModalOpen(true)
                formikHelpers.resetForm()
            } else {
                setIsErrorModalOpen(true)
            }

        }
    })

    const onClick = () => {
        setIsSuccessModalOpen(true)
        contactForm.resetForm()
    }

    return (
        <>
            <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
                <div className={styles.popup_title}>
                    Success!
                </div>
                <div className={styles.popup_subtitle}>
                    Your message has been sent successfully.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn style={{padding: "20px 60px", marginTop: "40px"}} title={"Ok"} onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
                <div className={styles.popup_title}>
                    Failed!!
                </div>
                <div className={styles.popup_subtitle}>
                    Something went wrong. Try again later or contact our support team.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn style={{padding: "20px 60px", marginTop: "40px"}} buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <div className={styles.form}>
                <div className={classnames(styles.gray_bg, styles.block)}>
                    <form onSubmit={contactForm.handleSubmit}>
                        <div className={styles.input_wrapper}>
                            <MyInput
                                id={"name"}
                                name={"name"}
                                placeholder='Name and Surname'
                                value={contactForm.values.name}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.name
                                && contactForm.errors.name
                                && <div className={styles.error_text}>{contactForm.errors.name}</div>
                            }
                        </div>

                        <div className={styles.input_wrapper}>
                            <MyInput
                                id={"phone"}
                                name={"phone"}
                                placeholder='Phone number'
                                value={contactForm.values.phone}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.phone
                                && contactForm.errors.phone
                                && <div className={styles.error_text}>{contactForm.errors.phone}</div>
                            }
                        </div>


                        <div className={styles.input_wrapper}>
                            <MyInput
                                id={"email"}
                                name={"email"}
                                placeholder='Email address'
                                value={contactForm.values.email}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.email
                                && contactForm.errors.email
                                && <div className={styles.error_text}>{contactForm.errors.email}</div>
                            }
                        </div>

                        <div className={styles.input_wrapper}>
                            <MyTextarea
                                id={"message"}
                                name={"message"}
                                placeholder='Message'
                                value={contactForm.values.message}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.message
                                && contactForm.errors.message
                                && <div className={styles.error_text}>{contactForm.errors.message}</div>
                            }
                        </div>

                        <div className={styles.contacts_button_wrapper}>
                            <div className={styles.button_block}>
                                <MyBtn title={"Send message"} large type={"submit"}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>

    );
};

export default ContactUsForm;
